import BtnMax from '../btn_max';
import Config from '../../config';
import CloseBtn from '../close_btn';

import styles from './index.module.css';
function WinningList({visible,vinList,onClick}) {
    return (
        <>
        {visible &&
        <div className={styles.wlist}>
            <div className={styles.mask}></div>
            <div className={styles.con}>
                <div className="pop_wrap popbg">
                    <div className="pop_title_2 w424">中奖名单公示</div>
                    <div className="pop_c">
                        <div className={styles.t_pop_wrap}><img className={styles.t_pop} src={Config.ossDoMain+'/images/t_pop_1.webp'} alt=""/></div>
                        <div className={styles.n}>中奖名单</div>
                        <ul className={styles.ul}>
                            <li></li>
                            {vinList.level_1 && vinList.level_1.map(item=><li key={item.cardNo}>{item.cardNo}</li>)}
                            <li></li>
                        </ul>
                        <div className={styles.t_pop_wrap}><img className={styles.t_pop} src={Config.ossDoMain+'/images/t_pop_2.webp'} alt=""/></div>
                        <div className={styles.n}>中奖名单</div>
                        <div className={styles.scroll_wrap}>
                        <ul className={styles.ul}>
                            {vinList.level_2 && vinList.level_2.map(item=><li key={item.cardNo}>{item.cardNo}</li>)}
                        </ul>
                        </div>
                        <div className={styles.t_pop_wrap}><img className={styles.t_pop} src={Config.ossDoMain+'/images/t_pop_3.webp'} alt=""/></div>
                        <div className={styles.n}>中奖名单</div>
                        <div className={styles.scroll_wrap}>
                        <ul className={styles.ul}>
                            {vinList.level_3 && vinList.level_3.map(item=><li key={item.cardNo}>{item.cardNo}</li>)}
                        </ul>
                        </div>
                        <div className={styles.t_pop_wrap}><img className={styles.t_pop} src={Config.ossDoMain+'/images/t_pop_4.webp'} alt=""/></div>
                        <div className={styles.n}>中奖名单</div>
                        <div className={styles.scroll_wrap}>
                        <ul className={styles.ul}>
                            {vinList.level_4 && vinList.level_4.map(item=><li key={item.cardNo}>{item.cardNo}</li>)}
                        </ul>
                        </div>
                        <div className={styles.t_pop_wrap}><img className={styles.t_pop} src={Config.ossDoMain+'/images/t_pop_5.webp'} alt=""/></div>
                        <div className={styles.n}>中奖名单</div>
                        <div className={styles.scroll_wrap}>
                        <ul className={styles.ul}>
                            {vinList.level_5 && vinList.level_5.map(item=><li key={item.cardNo}>{item.cardNo}</li>)}
                        </ul>
                        </div>
                        <div className={styles.t_pop_wrap}><img className={styles.t_pop} src={Config.ossDoMain+'/images/t_pop_6.webp'} alt=""/></div>
                        <div className={styles.n}>中奖名单</div>
                        <div className={styles.scroll_wrap}>
                        <ul className={styles.ul}>
                            {vinList.level_6 && vinList.level_6.map(item=><li key={item.cardNo}>{item.cardNo}</li>)}
                        </ul>
                        </div>
                    </div>
                    <div className="h38"></div>
                </div>
                <div className={styles.close} onClick={onClick}>
                    <CloseBtn></CloseBtn>
                </div>
            </div>
        </div>
        }
        </>
    );
  }
  
  export default WinningList;
  