import {useCallback, useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import NavCard from '../../components/nav_card';

import Share from '../../components/share';
import Util from '../../utils';
import Config from '../../config';

import getQrCode from "../../request/getQrCode";
import upload from "../../request/upload";
function Task() {

  const backgroundStyle = {
    backgroundImage: `url(${Config.ossDoMain}/images/task_bg.webp)`,
    backgroundSize: 'cover',
     backgroundPosition: 'center center'
  };
  const [visibleShare, setVisibleShare] = useState(false)
  const [lotteryNumber, setLotteryNumber] = useState('')
  const [src, setSrc] = useState('')
  const [url, setUrl] = useState('')
  const [qrcode, setQrcode] = useState('')

  const navigate = useNavigate();
  const isMiniprogram = localStorage.getItem('miniprogram')
  const onGoSend = useCallback(() => {
    navigate('/send');
  },[])

  const onShowShare = () => {
    drawCard()
    setVisibleShare(true)
  }

  const onGoBind = useCallback(() => {
    const backUrl = encodeURIComponent(Config.doMain)
    const code = window.AMEX_ALLPARAMS.code
    console.log("跳转地址",`${Config.bindCardUrl}?sourceFlag=amex20anniversary&backUrl=${backUrl}&code=${code}`)
    // eslint-disable-next-line no-undef
    if(isMiniprogram==="1" && !!wx){
      // eslint-disable-next-line no-undef
      wx.miniProgram.navigateTo({
          url:`${Config.bindCardUrl}?sourceFlag=amex20anniversary&backUrl=${backUrl}&code=${code}`
      });
    }
    // eslint-disable-next-line no-undef
    if(!!my){
      // eslint-disable-next-line no-undef
      my.navigateTo({
          url:`${Config.bindCardUrl}?sourceFlag=amex20anniversary&backUrl=${backUrl}&code=${code}`
      });
    }
  },[])

  const onShowCard = useCallback(() => {
    navigate('/card');
  },[])

  const onHideShare = useCallback(() => {
    setVisibleShare(false)
  },[])

  // 支付宝需上传换取url地址
  const onAlipayUpload = (data) => {
    const param = {
      id:new Date().getTime(),
      imgData:data,
    }
    const blob = Util.base64ToBlob(data);
    const formData = new FormData();
    formData.append('file', blob, 'poster.png');

    console.log("什么参数", param)
    upload(param.id, formData).then(res=>{
      console.log("upload:",res)
      const _url = Config.origin+res
      if(res){
        setUrl(_url)
        // 实在海报图缓存
        try{
          lotteryNumber && localStorage.setItem(lotteryNumber+'_url',_url)
          !lotteryNumber && localStorage.setItem('default_url',_url)
        }catch(err){
          console.log(err)
        }
      }
    }).catch(err=>{
      console.log(err)
      window._alert(err)
    })
  }

  const drawCard = async () => {

    if(lotteryNumber && localStorage.getItem(lotteryNumber)){
      setSrc(localStorage.getItem(lotteryNumber))
      console.log("分享海报->缓存",lotteryNumber)
      console.log("loc",localStorage.getItem(lotteryNumber))
      if(lotteryNumber && localStorage.getItem(lotteryNumber+'_url')){
        setUrl(localStorage.getItem(lotteryNumber+'_url'))
      }
      return
    }
    if(!lotteryNumber && localStorage.getItem('default')){
      setSrc(localStorage.getItem('default'))
      console.log("分享海报->缓存",'default')
      console.log("loc",localStorage.getItem('default'))
      if(!lotteryNumber && localStorage.getItem('default_url')){
        setUrl(localStorage.getItem('default_url'))
      }
      return
    }
    if(src || url){
      console.log("分享海报->读内存")
      return
    }
    // 随机一个颜色的卡片
    const randomIndex = Math.floor(Math.random()*5)+1
    console.log(lotteryNumber,qrcode)
    let param = {
      with:725,
      height: 1090,
      // with:529,
      // height: 796,
      bg:`/images/p_card.webp`,
      mask:`/images/p_mask.webp`,
      qrcode:qrcode,
      card:`/images/p_card_${randomIndex}.webp`,
      s_num:lotteryNumber?`幸运号码：${lotteryNumber}`:''
    }
    const _data = await Util.poster(param)
    if(!_data) return
    setSrc(_data)
    console.log("分享海报->重新生成")
    // 实在海报图缓存
    try{
      lotteryNumber && localStorage.setItem(lotteryNumber,_data)
      !lotteryNumber && localStorage.setItem('default',_data)
    }catch(err){
      console.log(err)
    }
    // 支付宝需上传换取url地址
    if(Util.getChannelCode()==='ALIPAY'){
      onAlipayUpload(_data)
    }
  }

  let retryCount = 0
  const onQrCode = () => {
    const param = {
      channelCode: Util.getChannelCode()
    }
    const _url = localStorage.getItem(`${param.channelCode}_qrcode_url`)
    if(_url){
      setQrcode(_url)
      return
    }
    getQrCode(param).then(res=>{
      console.log("getQrCode:",res)
      setQrcode(res)
      try{
        localStorage.setItem(`${param.channelCode}_qrcode_url`,res)
      }catch(err){

      }
    }).catch(err=>{
      if(retryCount<2){
        retryCount++;
        onQrCode()
      }else{
        setQrcode(`/images/${param.channelCode}_qrcode.png`)
      }
      console.log(err)
    })
  }


  const init = () => {
   const lotNo = Util.getUrlParam('lotNo')
   console.log("抽奖卡号",lotNo)
   onQrCode()
   if(lotNo){
    setLotteryNumber(lotNo)
   }
  }

  useEffect(()=>{
    if(!qrcode || !lotteryNumber) return
    // 有抽奖卡号的，弹出一下抽奖卡
    onShowShare()
  },[lotteryNumber,qrcode])

  
  useEffect(()=>{
    init()
  },[])
  return (
    <>
    <div className="Task page_container" style={backgroundStyle}>
      <div className="t_container">
        <div className="task_list">
          <div className='task_item' onClick={onGoBind}>
            <div className="task_cic"></div>
            <img className="task_text" src={`${Config.ossDoMain}/images/task_text_1.webp`} alt=""/>
            <div className="task_btn_wrap">
              <img className="task_btn" src={`${Config.ossDoMain}/images/task_btn_1.webp`} alt=""/>
            </div>
          </div>
          <div className='task_item' onClick={onShowShare}>
            <div className="task_cic"></div>
            <img className="task_text" src={`${Config.ossDoMain}/images/task_text_2.webp`} alt=""/>
            <div className="task_btn_wrap">
              <img className="task_btn" src={`${Config.ossDoMain}/images/task_btn_2.webp`} alt=""/>
            </div>
          </div>
          <div className='task_item' onClick={onGoSend}>
            <div className="task_cic"></div>
            <img className="task_text" src={`${Config.ossDoMain}/images/task_text_3.webp`} alt=""/>
            <div className="task_btn_wrap">
              <img className="task_btn" src={`${Config.ossDoMain}/images/task_btn_3.webp`} alt=""/>
            </div>
          </div>
          <img className="tip" src={`${Config.ossDoMain}/images/tip.webp`} alt=""/>
        </div>
      </div>
    </div>
    <NavCard onClick={onShowCard}></NavCard>
    <Share visible={visibleShare} src={src} url={url} onClick={onHideShare}></Share>
    </>
  )
}
export default Task;