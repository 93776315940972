import { useCallback, useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";

import getQrCode from "../../request/getQrCode";
import getWinPrizeList from "../../request/getWinPrizeList";

import NavCard from '../../components/nav_card';
import Tip from '../../components/tip';

import Util from '../../utils';
import Config from '../../config';
import BtnMax from '../../components/btn_max';

function Home({logon}) {
    const [visibleTipDetail, setVisibleTipDetail] = useState(false)
    const [qrcode, setQrcode] = useState('')
    
    const backgroundStyle = {
      backgroundImage: `url(${Config.ossDoMain}/images/h_bg.webp)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    };
    const [src] = useState('/images/tip.webp')
    let navigate = useNavigate();
    const isMiniprogram = localStorage.getItem('miniprogram')
    const onGoSend = useCallback(() => {
        navigate('/send');
    },[])

    const onGetWinPrizeList = useCallback(() => {
      getWinPrizeList().then(res=>{
        console.log("count",res)
        if(res && Object.keys(res).length>0){
          try{
            localStorage.setItem('has_winning',1)
          }catch(err){
            console.log(err)
          }
        }else{
          try{
            localStorage.setItem('has_winning',0)
          }catch(err){
            console.log(err)
          }
        }
       
      }).catch(err=>{
        console.log(err)
      })
    },[])

    let retryCount = 0
    const onQrCode = useCallback(() => {
      const param = {
        channelCode:Util.getChannelCode()
      }
      const _url = localStorage.getItem(`${param.channelCode}_qrcode_url`)
      if(_url){
        setQrcode(_url)
        return
      }
      getQrCode(param).then(res=>{
        console.log("getQrCode:",res)
        setQrcode(res)
        try{
          localStorage.setItem(`${param.channelCode}_qrcode_url`,res)
        }catch(err){
  
        }
      }).catch(err=>{
        if(retryCount<2){
          retryCount++;
          onQrCode()
        }else{
          setQrcode(`/images/${param.channelCode}_qrcode.webp`)
        }
        console.log(err)
      })
    },[])
  
   
    const onHideTipDetail = useCallback(() => {
      setVisibleTipDetail(false)
      onGoSend()
      console.log("ok!")
    },[])

    useEffect(()=>{
      if(logon===true){
        console.log("执行了吗")
        onQrCode()
        onGetWinPrizeList()
      }
    },[logon, onQrCode])

    let _count = 0
    const onDebug = useCallback(() => {
      console.log(_count)
      _count++;
      if(localStorage.getItem('_debug')==='1'){
        localStorage.setItem('_debug','0')
      }
      if(_count===19){
        localStorage.setItem('_debug','1')
      }
    },[_count])
    return (
      <>
      <div className="Home page_container" style={backgroundStyle} onClick={onDebug}>
      <div className="h_container">
        <div className='h_btn_wrap'>
        <BtnMax onClick={onGoSend}>
            <img src={`${Config.ossDoMain}/images/t_start.webp`} alt="开始"/>
        </BtnMax>
        </div>
      </div>
      
      </div>
      <Tip visible={visibleTipDetail} src={src} onClick={onHideTipDetail}></Tip>
      </>
    )
}
    export default Home;