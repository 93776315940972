import CloseBtn from '../close_btn';
import styles from './index.module.css';
import Config from '../../config';
function Rule({visible,code,onClick}) {
    
    return (
        <>
        {visible &&    
        <div className={styles.rule}>
            <div className={styles.mask}></div>
            <div className={styles.con}>
            <div className="h38"></div>
            <div className="pop_wrap popbg">
                <div className={styles.pop_title}>兑换编码</div>
                <div className={styles.pop_code}>{code}</div>
                <div className={styles.pop_title2}>境内指定乐园370元立减券使用说明</div>
                <div className="pop_c">
                    <div className="h38"></div>
                    <div className={styles.scroll}>
                        <img src={`${Config.ossDoMain}/images/code_rule.webp`} alt=""/>
                    </div>
                    <div className="h38"></div>
                </div>
            </div>
            </div>
            <div className={styles.close} onClick={onClick}>
                <CloseBtn></CloseBtn>
            </div>
        </div>
        }
        </> 
    );
  }
  
  export default Rule;
  