function loadImage(src) {
    return new Promise(function (resolve, reject) {
        let img = new Image()
        img.crossOrigin = 'anonymous'
        img.onload = function(){
            resolve(img)
        }
        img.src = src
    })
}
async function qrcode(param){
    const canvas = document.createElement('canvas');
    canvas.width = 200
    canvas.height = 200
    const ctx = canvas.getContext('2d')
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    console.log('合成 qrcode:',param.qrcode,getChannelCode())
    if(param.qrcode){
    const _qrcode = await loadImage(param.qrcode)
    if(getChannelCode()==='WECHAT'){
        console.log("ioioio",_qrcode)
        ctx.drawImage(_qrcode, 0, 0, _qrcode.width, _qrcode.height, 10, 10,canvas.width-20,canvas.height-20);
    }
    if(getChannelCode()==='ALIPAY'){
        ctx.drawImage(_qrcode, 34, 34, 228, 228, 0, 0,canvas.width,canvas.height);
    }
    
    }
    console.log("ioioio2")
    const _mask = await loadImage(param.mask)
    console.log("ioioio3",_mask)
    ctx.globalCompositeOperation = 'destination-in'
    ctx.drawImage(_mask, 0, 0, _mask.width, _mask.height, 0, 0,canvas.width,canvas.height);
    return canvas
}

const cacheImgs = {}
async function poster(param){
    console.log("poster")
    const canvas = document.createElement('canvas');
    canvas.width = param.with
    canvas.height = param.height
    const ctx = canvas.getContext('2d')
    console.log("_bg",cacheImgs[param.bg],param.bg)
    const _bg = cacheImgs[param.bg]?cacheImgs[param.bg]:await loadImage(param.bg)
    console.log("_bg",_bg)
    if(!cacheImgs[param.bg]){
        cacheImgs[param.bg] = _bg
    }
    console.log("zheli")
    ctx.drawImage(_bg, 0, 0);
    const _card = cacheImgs[param.card]?cacheImgs[param.card]:await loadImage(param.card)
    if(!cacheImgs[param.card]){
        cacheImgs[param.card] = _card
    }
    ctx.drawImage(_card, 0, 0, _card.width, _card.height, 314, 814, 374, 237);
    const _qr_canvas = cacheImgs[param.qrcode]?cacheImgs[param.qrcode]:await qrcode(param)
    if(!cacheImgs[param.qrcode]){
        cacheImgs[param.qrcode] = _qr_canvas
    }
    ctx.drawImage(_qr_canvas, 0, 0, _qr_canvas.width, _qr_canvas.height, 68, 772, _qr_canvas.width, _qr_canvas.height);
    ctx.fillStyle = "white";
    ctx.font = "34px Arial";
    ctx.textAlign = "center"
    ctx.textBaseline = 'middle';
    ctx.fillText(param.s_num, 362, 40);
    return canvas.toDataURL('image/webp')
}

async function prizePoster(param){
    const canvas = document.createElement('canvas');
    canvas.width = param.with
    canvas.height = param.height
    const ctx = canvas.getContext('2d')
    const _bg = cacheImgs[param.bg]?cacheImgs[param.bg]:await loadImage(param.bg)
    if(!cacheImgs[param.bg]){
        cacheImgs[param.bg] = _bg
    }
    ctx.drawImage(_bg, 0, 0);
    const _card = cacheImgs[param.card]?cacheImgs[param.card]:await loadImage(param.card)
    if(!cacheImgs[param.card]){
        cacheImgs[param.card] = _card
    }
    ctx.drawImage(_card, 0, 0, _card.width, _card.height, 314, 814, 374, 237);
    
    const _prizePic = cacheImgs[param.prizePic]?cacheImgs[param.prizePic]:await loadImage(param.prizePic)
    if(!cacheImgs[param.prizePic]){
        cacheImgs[param.prizePic] = _prizePic
    }
    ctx.drawImage(_prizePic, 0, 0, _prizePic.width, _prizePic.height, 0, 308, _prizePic.width, _prizePic.height);
    const _qr_canvas = cacheImgs[param.qrcode]?cacheImgs[param.qrcode]:await qrcode(param)
    if(!cacheImgs[param.qrcode]){
        cacheImgs[param.qrcode] = _qr_canvas
    }
    ctx.drawImage(_qr_canvas, 0, 0, _qr_canvas.width, _qr_canvas.height, 68, 772, _qr_canvas.width, _qr_canvas.height);
    ctx.fillStyle = "white";
    ctx.font = "34px Arial";
    ctx.textAlign = "center"
    ctx.textBaseline = 'middle';
    ctx.fillText(param.s_num,  362, 40);
    return canvas.toDataURL('image/webp')
}

// 对象转url参数
function objectToUrlParams(obj) {
    const params = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            // 确保值不是undefined，否则URL会包含类似key=undefined的参数
            if (value !== undefined) {
                params.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
            }
        }
    }
    return params.join('&');
}
const getUrlAllParams = () => {
    const params = new URLSearchParams(window.location.search);
    const allParams = Object.fromEntries(params);
    console.log(allParams)
    return allParams;
}

const getUrlParam = (key) => {
    const params = new URLSearchParams(window.location.search);
    const value = params.get(key)
    console.log(value)
    return value;
}

// 验证手机号
const validatePhoneNumber = (phoneNumber) => {
    const regex = /^1[3456789]\d{9}$/;
    return regex.test(phoneNumber);
  }

// 获取url上渠道
const getChannelCode = () => {
    return changeClient((window.AMEX_ALLPARAMS && window.AMEX_ALLPARAMS.client)?window.AMEX_ALLPARAMS.client:'')
}

// 渠道修改
const changeClient = (str) => {
    let _str = ''
    switch(str){
        case 'EC_WECHAT_MP': 
        _str = 'WECHAT'
        break;
        case 'EC_ALIPAY_MP': 
        _str = 'ALIPAY'
        break;
        default: 
        break;
    }
    if(!_str){
        _str = localStorage.getItem('userAgent')?localStorage.getItem('userAgent'):''
    }
    if(!_str && navigator.userAgent.toLowerCase().indexOf('miniprogram') !== -1){
        _str= 'WECHAT'
    }
    if(!_str && navigator.userAgent.indexOf('AliApp') > -1){
        _str= 'ALIPAY'
    }
    if(!_str){
        _str= 'WECHAT'
    }
    return _str
}

// base64转blob
function base64ToBlob(base64) {
    const parts = base64.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
  
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
  
    return new Blob([uInt8Array], { type: contentType });
  }
const Util = {
    loadImage:loadImage,
    poster:poster,
    prizePoster:prizePoster,
    objectToUrlParams:objectToUrlParams,
    getUrlAllParams:getUrlAllParams,
    getUrlParam:getUrlParam,
    validatePhoneNumber:validatePhoneNumber,
    getChannelCode:getChannelCode,
    changeClient:changeClient,
    base64ToBlob:base64ToBlob
}
export default Util;