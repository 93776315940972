import {useEffect, useState} from 'react';
import BtnMid from '../btn_mid';
import CloseBtn from '../close_btn';
import Config from '../../config';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import styles from './index.module.css';
function MyCard({listData,onGoInfo, onGoTask, onClose}) {
    const [cardList, setCardList] = useState([])
    const [initialSlide] = useState(0)
    const [loaded, setLoaded] = useState(false)

    const onImgLoaded = () => {
       setLoaded(true)
    }
    useEffect(()=>{
        setCardList(listData)
    },[listData])
    return (
    <div className={styles.card_wrap}>
        {/* <div className={styles.mask}></div> */}
        {/* <div className={styles.c_box} onClick={onClose}><CloseBtn></CloseBtn></div> */}
        <div className={styles.swiper_wrap}>
        <Swiper
        slidesPerView={3} 
        spaceBetween={20}
        centeredSlides={true} 
        initialSlide={initialSlide}
        >
        {cardList.map((item,index) =>    
        <SwiperSlide key={index}>
            {item.status===0 && <img className={styles.noWin} src={`${Config.ossDoMain}/images/icon_noWin.webp`} alt="未中奖标识"/>}
            {(item.status===1||item.status===2) && <img className={styles.noWin} src={`${Config.ossDoMain}/images/icon_win.webp`} alt="中奖标识"/>}
            <div className={styles.h24}></div>
            <div className={styles.imgbox}>
                {!loaded && <div className='loadingAn'></div>}
                <img className={styles.card} src={item.src} alt="" onLoad={onImgLoaded}/>
            </div>
            {item.url && <img className={styles.url} src={item.url} alt=""/>}
            
            <div className={styles.h44}></div>
            {/* {item.status===-1 && 
            <div className={styles.b_box} onClick={onClose}><CloseBtn></CloseBtn></div>
            } */}
            {item.status===0 && 
            <div className={styles.zw}></div>
            }
            {item.status===1 && 
            <BtnMid onClick={()=>{
                console.log("zheli",item.level)
                onGoInfo(item.level,item.couponCode)}}>
                {item.level===2 && <img src={`${Config.ossDoMain}/images/t_djckdjgz.webp`} alt="点击查看兑奖规则"/>}
                {item.level!==2 && <img src={`${Config.ossDoMain}/images/t_qwtxsjxx.webp`} alt="前往填写收件信息"/>}
            </BtnMid>
            }
            {item.status===2 && 
            <BtnMid onClick={()=>{onClose('已留资')}}>
                <img src={`${Config.ossDoMain}/images/t_qwtxsjxx.webp`} alt="前往填写收件信息"/>
            </BtnMid>
            }
        </SwiperSlide>
        )}
        </Swiper>
        </div>
    </div>
    );
  }
  
  export default MyCard;
  