import { useCallback, useState } from "react";
import Util from "../../utils";
import savePrizeMessage from "../../request/savePrizeMessage";
import styles from './index.module.css';
import Config from "../../config";
function EditInfo({visible,level,onCallBack}) {

    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    const [address, setAddress] = useState('')
    const handleNameChange = useCallback((e) => {
        setName(e.target.value)
    },[])
    const handleMobileChange = useCallback((e) => {
        setMobile(e.target.value)
    },[])
    const handleAddressChange = useCallback((e) => {
        setAddress(e.target.value)
    },[])

    // 验证
    const verFn = () => {
        if(!name){
            return {
                result:false,
                errMsg:'请填写姓名'
            }
        }
        if(!mobile){
            return {
                result:false,
                errMsg:'请填写联系方式'
            }
        }
        if(!Util.validatePhoneNumber(mobile)){
            return {
                result:false,
                errMsg:'联系方式格式错误'
            }
        }
        if(!address){
            return {
                result:false,
                errMsg:'请填写收货地址'
            }
        }
        return {
            result:true,
            errMsg:''
        }
    }
    const onSavePrizeMessage = () => {
        const param = {
            name, //姓名
            mobile, //手机号
            address, //地址
            channelCode: Util.getChannelCode(), //渠道
            level: level
        }
        const _res = verFn()
        if(!_res.result){
            window._alert(_res.errMsg)
            return
        }
        
        console.log("param",param)
        savePrizeMessage(param).then(res=>{
            console.log(res)
            window._alert('提交成功')
            onCallBack()
        }).catch(err=>{
            window._alert(err)
            onCallBack()
        })
    }
    
    return (
        <>
        {visible &&    
        <div className={styles.info}>
            <div className={styles.mask}></div>
            <div className={styles.con}>
            <div className="pop_wrap pop_info">
                <div className="pop_title_2">填写信息</div>
                <div className="pop_c">
                    <div className="h50"></div>
                <input className={styles.input_style} placeholder='姓名' maxLength={20} onChange={handleNameChange}/>
                <input className={styles.input_style} placeholder='联系方式' maxLength={11} onChange={handleMobileChange}/>
                <textarea className={styles.textarea_style} placeholder='收货地址' maxLength={120} onChange={handleAddressChange}></textarea>
                <div className={styles.h72}>
                    <text className={styles.tip}>以上获奖者信息仅用于本次活动的奖品抽取及兑换</text>
                </div>
                <div className={styles.btn_wrap} onClick={onSavePrizeMessage}>
                    <div className={styles.btn_con}>
                        <img src={`${Config.ossDoMain}/images/t_queren.webp`} alt="确认"/>
                    </div>
                </div>
                <div className={styles.h72}></div>

                </div>
            </div>
            </div>
        </div>
        }
        </> 
    );
  }
  
  export default EditInfo;
  