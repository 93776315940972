
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState, useRef } from 'react';

import Config from "../../config";
import NavCard from '../../components/nav_card';

import PreCard from '../../components/pre_card';

import getOpportunitys from "../../request/getOpportunitys";
import startGame from "../../request/startGame";
import endGame from "../../request/endGame";
import getQrCode from "../../request/getQrCode";
import upload from "../../request/upload";

import Util from '../../utils';
import game from './game';

function Send() {

    const backgroundStyle = {
        backgroundImage: `url(${Config.ossDoMain}/images/bg.webp)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
    };
    const [gameStatus, setGameStatus] = useState(0)
    const [count, setCount] = useState(0)
    const [visible, setVisible] = useState(false)
    const [qrcode, setQrcode] = useState('')   
    const [second, setSecond] = useState(10)  
    const [hasWinning, setHasWinning] = useState('0')   

    const [listData, setListData] = useState([])
    
    let navigate = useNavigate();

    const onStartGame = useCallback(() => {
        console.log("count",count)
        if(count<1) {
            onGoTask()
            return
        }
        setGameStatus(1)
    },[count])

    const onGoTask = useCallback(() => {
        navigate('/task');
    },[])

    const onClose = useCallback(() => {
        setVisible(false)
        onGoTask()
    },[])
 
    const onShowCard = useCallback(() => {
        navigate('/card');
    },[])

    const onGetOpportunitys = useCallback(() => {
        getOpportunitys().then(res=>{
            console.log("zheli",res)
            // if(res<1){
            //     onGoTask()
            // }
            setCount(res)
        }).catch(err=>{
            window._alert(err)
        })
    },[])

    // 支付宝需上传换取url地址
  const onAlipayUpload = async (id,data) => {
    const param = {
      id,
      imgData:data,
    }
    const blob = Util.base64ToBlob(data);
    const formData = new FormData();
    formData.append('file', blob, 'poster.webp');

    return new Promise(function(resolve){
      upload(param.id,formData).then(res=>{
        console.log("upload:",res)
        const _url = Config.origin+res
        resolve(_url)
      }).catch(err=>{
        console.log(err)
        window._alert(err)
      })
    })
  }
  const drawCard = useCallback(async (lotNo) => {
    // 随机一个颜色的卡片
    const randomIndex = Math.floor(Math.random()*5)+1
    let param = {
      with:725,
      height: 1090,
      // with:529,
      // height: 796,
      bg:`/images/p_card.webp`,
      mask:`/images/p_mask.webp`,
      qrcode:qrcode,
      card:`/images/p_card_${randomIndex}.webp`,
      s_num:lotNo?`幸运号码：${lotNo}`:''
    }
    const _data = await Util.poster(param)
    return !_data?'':_data
  },[qrcode])

    // 获取中间结果数据
  // status 0 未中奖，1 中奖
  const getListData = useCallback(async (pc_data) => {
    //console.log("抽奖卡列表",cardList,index)
    // let pc_data = cardList
    console.log("pc_data",pc_data,pc_data.length<1)
    if(pc_data.length<1) return

    let _openStatus = true
    let data = []
    for(let item of pc_data){
      let src = ''
      let url = ''
      console.log("item",item)
      
         // 支付宝需上传换取url地址
        // if(Util.getChannelCode()==='ALIPAY' && item.sharePicPath){
        //   src = Config.origin+item.sharePicPath
        //   url = Config.origin+item.sharePicPath
        // }else{
        //   src = item.status ? await drawPrizeCard(item.cardNo, item.msg||'',item.prizeType) : await drawCard(item.cardNo, item.msg||'');
        // }
        src = await drawCard(item.cardNo);
        console.log("海报图走的生成")
        // 支付宝需上传换取url地址
        if(Util.getChannelCode()==='ALIPAY' && !url){
         url = await onAlipayUpload(item.id, src)
        }
      data.push({
        src:src,
        url:url,
        status: _openStatus?(item.status?(item.mobile?2:1):0):-1,
        hasInfo: (item.name && item.address)? true : false,
        level: item.level
      })
      // 实在海报图缓存
      try{
        item.cardNo && src && localStorage.setItem(item.cardNo,src)
        item.cardNo && url && localStorage.setItem(item.cardNo+'_url',url)
      }catch(err){
        console.log(err)
      }
    }
    console.log('listData::')
    console.log(data)
    setListData(data)
    setVisible(true)
  },[drawCard])

    

    const gameOverHandel = useCallback((num)=>{
        const params = {
            channelCode: Util.getChannelCode(),
            num
        }
        if(window.AMEX_ALLPARAMS.referrerUserId){
            params['shareUid'] = window.AMEX_ALLPARAMS.referrerUserId
        }
        endGame(params).then((res)=>{
            if(!res || res.length<1) {
                onGoTask()
                return
            }
            setVisible(true)
            // 统一洗一下数据
            let data = []
            for(let item of res){
                data.push({
                    id:parseInt(item),
                    address: null,
                    cardNo: item,
                    enable: 0,
                    mobile: null,
                    name: null,
                    prizeType: null,
                    sharePicPath: null,
                    status: false,
                })
            }
            // 绘制获得的卡片
            getListData(data)
        })
    },[getListData, onGoTask])

    let retryCount = 0
    const onQrCode = useCallback(() => {
        const param = {
          channelCode:Util.getChannelCode()
        }
        const _url = localStorage.getItem(`${param.channelCode}_qrcode_url`)
        if(_url){
          setQrcode(_url)
          return
        }
        getQrCode(param).then(res=>{
          console.log("getQrCode:",res)
          setQrcode(res)
          try{
            localStorage.setItem(`${param.channelCode}_qrcode_url`,res)
          }catch(err){
    
          }
        }).catch(err=>{
          if(retryCount<2){
            retryCount++;
            onQrCode()
          }else{
            setQrcode(`/images/${param.channelCode}_qrcode.webp`)
          }
          console.log(err)
        })
      },[])
    
      useEffect(()=>{
        onQrCode()
      },[onQrCode])

    useEffect(()=>{
       
      onGetOpportunitys()
      const add_bonus_tip = localStorage.getItem('add_bonus')
      if(add_bonus_tip){
        window._alert(add_bonus_tip)
        try{
            localStorage.removeItem('add_bonus')
          }catch(err){
            console.log(err)
        }
      }
      const has_winning = localStorage.getItem('has_winning')
      setHasWinning(has_winning)
    },[])

    const initGame = ()=>{
        // 游戏随机掉落1到3张好运卡，百分之10 3张 百分之20 2张 百分之70 1张
        let c = Math.floor(Math.random()*10)+1
        let card_count = 1
        if(c>9){
            card_count = 3
        }else if(c>7){
            card_count = 2 
        }else{
            card_count = 1 
        }
        console.log("3",card_count)
        new game({
            canvas: document.getElementById('gameCanvas'),
            duration: 10,
            milliseconds‌: 200,
            card_count,
            currentTime: (t)=>{
                setSecond(t)
            },
            gameOver: (res)=>{
                console.log("游戏结束",res)
                gameOverHandel(res)
            }
        })
        console.log("4")
        const params = {
            channelCode: Util.getChannelCode()
        }
        console.log("23")
        if(window.AMEX_ALLPARAMS.referrerUserId){
            params['shareUid'] = window.AMEX_ALLPARAMS.referrerUserId
        }
        console.log("5")
        startGame(params)
    }

    useEffect(()=>{
       if(gameStatus===1){
        initGame()
       }
     },[gameStatus])
    return (
      <>
      <div className="Send page_container" style={backgroundStyle}>
        <div className="s_container">
            {gameStatus===0 && <div className="s_rule_wrap">
                <img className="s_rule" src={`${Config.ossDoMain}/images/rule.webp`} alt=""/>
                {hasWinning==='0' && count>0 && <div className="btn_start" onClick={onStartGame}>
                    <img className="s_start" src={`${Config.ossDoMain}/images/btn_t_3.webp`} alt=""/>
                </div>
                }
                {hasWinning==='0' && count===0 && <div className="btn_start" onClick={onGoTask}>
                    <img className="s_start" src={`${Config.ossDoMain}/images/t_hdgdcs.webp`} alt=""/>
                </div>
                }
                {hasWinning==='0' && count===-1 && <div className="btn_start"></div>}
                {hasWinning==='1' && <div className="btn_start" onClick={onShowCard}>
                    查看中奖卡号
                </div>
                }
                <div className="count">可玩次数：{count===-1?'':count}</div>
            </div>
            }
            {gameStatus===1 && <div className="s_game_wrap">
              <img className="g_title" src={`${Config.ossDoMain}/images/g_title.webp`} alt="游戏标题"/>
                <div className="game_box">
                    <img className="btn_t" src={`${Config.ossDoMain}/images/g_bg.webp`} alt="游戏背景"/>
                    <canvas className="canvas" id="gameCanvas" width="870" height="1139"></canvas>
                    <div className="time">{second}s</div>
                </div>
            </div> 
            }        
        </div>
      </div>
      <NavCard onClick={onShowCard}></NavCard>
      {visible && <PreCard listData={listData} onClose={onClose}></PreCard>}
      </>
    )
}
export default Send;